.gm-style .gm-style-iw {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.gm-style .gm-ui-hover-effect {
  position: relative !important;
  top: -5px !important;
  right: 0 !important;
  padding: 10px !important;
  margin-left: -15px !important;
  width: auto !important;
  height: auto !important;
}
.gm-style .gm-ui-hover-effect img {
  margin: 0 !important;
  width: 20px !important;
  height: 20px !important;
}

.btnHabilitaMapaDeCalor {
  position: absolute;
  z-index: 2;
  top: 115px;
  right: 10px;
  padding: 10px;
  font-size: 0.8rem;
  color: #333;
  background: #fff;
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) ;
}